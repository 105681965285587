import IconActionsClaims from "@/components/icons/IconActionsClaims";
import IconAllServices from "@/components/icons/IconAllServices";
import IconBalance from "@/components/icons/IconBalance";
import IconCharge from "@/components/icons/IconCharge";
import IconClaimNotProccess from "@/components/icons/IconClaimNotProccess";
import IconClaims from "@/components/icons/IconClaims";
import IconDataBalance from "@/components/icons/IconDataBalance";
import IconDenial from "@/components/icons/IconDenial";
import IconDisable from "@/components/icons/IconDisable";
import IconEnable from "@/components/icons/IconEnable";
import IconEOB from "@/components/icons/IconEOB";
import IconInsurancePlan from "@/components/icons/IconInsurancePlan";
import IconMonitoring from "@/components/icons/IconMonitoring";
import IconOpenClaim from "@/components/icons/IconOpenClaim";
import IconOpenPayment from "@/components/icons/IconOpenPayment";
import IconPatient from "@/components/icons/IconPatient";
import IconPatientResponsability from "@/components/icons/IconPatientResponsability";
import IconPayment from "@/components/icons/IconPayment";
import IconPayments from "@/components/icons/IconPayments";
import IconPreviewClaim from "@/components/icons/IconPreviewClaim";
import IconRefile from "@/components/icons/IconRefile";
import IconRefund from "@/components/icons/IconRefund";
import IconRooms from "@/components/icons/IconRooms";
import IconStatement from "@/components/icons/IconStatement";
import IconStatusApproved from "@/components/icons/IconStatusApproved";
import IconStatusComplete from "@/components/icons/IconStatusComplete";
import IconStatusDenied from "@/components/icons/IconStatusDenied";
import IconStatusDraft from "@/components/icons/IconStatusDraft";
import IconStatusNotSubmitted from "@/components/icons/IconStatusNotSubmitted";
import IconStatusRejected from "@/components/icons/IconStatusRejected";
import IconStatusSubmitted from "@/components/icons/IconStatusSubmitted";
import IconTracking from "@/components/icons/IconTracking";
import IconViewLedger from "@/components/icons/IconViewLedger";
import IconWriteOffs from "@/components/icons/IconWriteOffs";

import IconArchive from "../components/icons/IconArchive.vue";

export default {
  iconEnable: {
    component: IconEnable,
    props: {
      name: "icon-enable",
    },
  },
  iconDisable: {
    component: IconDisable,
    props: {
      name: "icon-disable",
    },
  },
  iconMonitoring: {
    component: IconMonitoring,
    props: {
      name: "icon-monitoring",
    },
  },
  iconOpenClaim: {
    component: IconOpenClaim,
    props: {
      name: "icon-open-claim",
    },
  },
  iconOpenPayment: {
    component: IconOpenPayment,
    props: {
      name: "icon-open-payment",
    },
  },
  iconRefile: {
    component: IconRefile,
    props: {
      name: "icon-refile",
    },
  },
  iconViewLedger: {
    component: IconViewLedger,
    props: {
      name: "icon-view-ledger",
    },
  },
  iconDataBalance: {
    component: IconDataBalance,
    props: {
      name: "icon-data-balance",
    },
  },
  iconAllServices: {
    component: IconAllServices,
    props: {
      name: "icon-all-services",
    },
  },
  iconCharge: {
    component: IconCharge,
    props: {
      name: "icon-charge",
    },
  },
  iconPayments: {
    component: IconPayments,
    props: {
      name: "icon-payments",
    },
  },
  iconWriteOffs: {
    component: IconWriteOffs,
    props: {
      name: "icon-write-offs",
    },
  },
  iconRefund: {
    component: IconRefund,
    props: {
      name: "icon-refund",
    },
  },
  iconClaims: {
    component: IconClaims,
    props: {
      name: "icon-claims",
    },
  },
  iconPatientResponsability: {
    component: IconPatientResponsability,
    props: {
      name: "icon-patient-responsability",
    },
  },
  iconStatusDraft: {
    component: IconStatusDraft,
    props: {
      name: "icon-status-draft",
    },
  },
  iconStatusSubmitted: {
    component: IconStatusSubmitted,
    props: {
      name: "icon-status-submitted",
    },
  },
  iconStatusApproved: {
    component: IconStatusApproved,
    props: {
      name: "icon-status-approved",
    },
  },
  iconStatusRejected: {
    component: IconStatusRejected,
    props: {
      name: "icon-status-approved",
    },
  },
  iconStatusDenied: {
    component: IconStatusDenied,
    props: {
      name: "icon-status-denied",
    },
  },
  iconStatusComplete: {
    component: IconStatusComplete,
    props: {
      name: "icon-status-complete",
    },
  },
  iconStatusNotSubmitted: {
    component: IconStatusNotSubmitted,
    props: {
      name: "icon-status-not-submitted",
    },
  },
  iconBalance: {
    component: IconBalance,
    props: {
      name: "icon-balance",
    },
  },
  iconPreviewClaim: {
    component: IconPreviewClaim,
    props: {
      name: "icon-preview-claims",
    },
  },
  iconActionsClaims: {
    component: IconActionsClaims,
    props: {
      name: "icon-actions-claims",
    },
  },
  iconPayment: {
    component: IconPayment,
    props: {
      name: "icon-payment",
    },
  },
  iconDenial: {
    component: IconDenial,
    props: {
      name: "icon-denial",
    },
  },
  iconPatient: {
    component: IconPatient,
    props: {
      name: "icon-patient",
    },
  },
  iconEOB: {
    component: IconEOB,
    props: {
      name: "icon-eob",
    },
  },
  iconTracking: {
    component: IconTracking,
    props: {
      name: "icon-tracking",
    },
  },
  IconInsurancePlan: {
    component: IconInsurancePlan,
    props: {
      name: "icon-insurance-plan",
    },
  },
  IconRooms: {
    component: IconRooms,
    props: {
      name: "icon-rooms",
    },
  },
  IconClaimNotProccess: {
    component: IconClaimNotProccess,
    props: {
      name: "icon-claim-not-proccess",
    },
  },
  IconStatement: {
    component: IconStatement,
    props: {
      name: "icon-statement",
    },
  },
  IconArchive: {
    component: IconArchive,
    props: {
      name: "icon-archive",
    },
  },
};
