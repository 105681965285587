/* eslint-disable prettier/prettier */
import Decimal from "decimal.js";

import VueI18n from "@/i18n.js";
import globalNumberFormat from "@/plugins/amountFormat";

const validDatesTableOtherInfo = (fields) => {
  if (fields.length > 0) {
    const allDatesValid = fields.every((arr) => {
      const from = arr.from_date;
      const fromDate = new Date(from).getTime();
      const toDate = new Date(arr.to_date).getTime();

      if (from && from !== "" && arr.to_date && arr.to_date !== "") {
        return fromDate <= toDate;
      }

      return true;
    });

    return allDatesValid;
  }

  return true;
};

const validAdmissionDischarge = (property) => {
  if (property.includes("mm:ss") || property.includes(":ss")) {
    return false;
  }

  return true;
};

const validDates = (from, to) => {
  if (from && from !== "" && to && to !== "") {
    const fromDate = new Date(from).getTime();
    const toDate = new Date(to).getTime();
    return fromDate <= toDate;
  }

  return true;
};

const validDatesTableServices = (services) => {
  if (services.length > 0) {
    const allDatesValid = services.every((service) => {
      if (service.from_service && service.to_service) {
        const fromDate = new Date(service.from_service).getTime();
        const toDate = new Date(service.to_service).getTime();

        return fromDate <= toDate;
      }

      return true;
    });

    return allDatesValid;
  }

  return true;
};

const validDatesPolicies = (policies, services) => {
  return policies.every((policy) => {
    if (!policy.eff_date || policy.eff_date === "") {
      return true;
    }

    return services.every((service) => {
      const from = new Date(service.from_service).getTime();
      const eff = new Date(policy.eff_date).getTime();
      if (from < eff) return false;

      if (policy.end_date && policy.end_date !== "") {
        const end = new Date(policy.end_date).getTime();
        if (from > end) {
          return false;
        }
      }

      return true;
    });
  });
};

const validParametersDates = (services, other, adDis) => {
  let obj = { isCorrect: true, tab: null };

  if (
    services.length > 0 &&
    services[0].from_service !== "" &&
    services[services.length - 1].to_service !== ""
  ) {
    const fromServ = new Date(services[0].from_service).getTime();
    const toServ = new Date(services[services.length - 1].to_service).getTime();

    // Dates Table Services VS Statement Covers Period
    if (other.from !== "" && other.to !== "") {
      const fromOther = new Date(other.from).getTime();
      const toOther = new Date(other.to).getTime();

      if (fromServ < fromOther || toServ > toOther) {
        obj.isCorrect = false;
        obj.tab = 3;
      }
    }

    // Dates Table Services VS Admission and Discharge
    if (adDis.admission_date !== "" && adDis.discharge_date !== "") {
      const fromAd = new Date(adDis.admission_date).getTime();
      const toAd = new Date(adDis.discharge_date).getTime();

      if (fromServ < fromAd || toServ > toAd) {
        obj.isCorrect = false;
        obj.tab = 1;
      }
    }
  }

  return obj;
};

const validStatementCoversDates = (other, adDis) => {
  const fromOther = new Date(other.from).getTime();
  const toOther = new Date(other.to).getTime();
  const fromAd = new Date(adDis.admission_date).getTime();
  const toAd = new Date(adDis.discharge_date).getTime();
  if (fromOther < fromAd || toOther > toAd) return false;

  return true;
};

const isTableDiagComplete = (dxs, format, patientChange) => {
  let isComplete = true;
  let hasAdmission = false;
  let hasPrincipal = false;

  for (let field of dxs) {
    if (
      (field.diagnosis_id === "" && dxs.indexOf(field) === 0) ||
      (format === "UB04" && patientChange && field.poa === "")
    ) {
      isComplete = false;
    }

    if (format === "UB04" && patientChange && field.admission === true) {
      hasAdmission = true;
    }

    if (field.principal === true) hasPrincipal = true;
  }

  if (format === "UB04" && patientChange && !hasAdmission) {
    isComplete = false;
  }

  if (!hasPrincipal) isComplete = false;

  return isComplete;
};

const isTableServComplete = (services, format) => {
  let isComplete = true;

  if (format === "UB04") {
    services.forEach((field) => {
      if (
        field.from_service === "" ||
        field.to_service === "" ||
        field.revenue_code_id === "" ||
        field.revenue_code_id === null ||
        field.price === "" ||
        field.units_of_service === "" ||
        parseFloat(field.units_of_service) === 0 ||
        field.total_charge === ""
      ) {
        isComplete = false;
      }
    });
  }

  return isComplete;
};

const validLimitUnits = (services) => {
  if (services.length > 0) {
    const isOverLimit = services.every((serv) => {
      const unit = serv.units_of_service;

      if (
        serv.units_limit !== null &&
        unit !== "" &&
        parseFloat(unit) > serv.units_limit
      ) {
        return true;
      } else {
        return false;
      }
    });

    return isOverLimit;
  }

  return false;
};

const validSequential = (services) => {
  for (let i = 1; i < services.length; i++) {
    if (
      services[i].from_service &&
      services[i].from_service !== "" &&
      services[i - 1].to_service &&
      services[i - 1].to_service !== ""
    ) {
      if (services[i].from_service < services[i - 1].to_service) {
        return false;
      }
    }
  }

  return true;
};

const validUnitServices = (services) => {
  for (let i = 0; i < services.length; i++) {
    for (let j = i + 1; j < services.length; j++) {
      if (
        services[i].from_service === services[j].from_service &&
        services[i].to_service === services[j].to_service &&
        services[i].revenue_code_id !== "" &&
        services[j].revenue_code_id !== "" &&
        services[i].revenue_code_id === services[j].revenue_code_id &&
        services[i].procedure_id === services[j].procedure_id
      ) {
        return false;
      }
    }
  }

  return true;
};

const validCorrectServices = (services) => {
  const isCorrect = services.every(
    (field) =>
      field.revenue_code_id === "" ||
      field.price === "" ||
      field.units_of_service === "" ||
      parseFloat(field.units_of_service) === 0 ||
      field.total_charge === ""
  );

  return isCorrect;
};

const setCode = (array) => {
  if (array.length > 0) {
    const fixedName =
      array[0].description && array[0].description !== ""
        ? array[0].name + " - " + array[0].description
        : array[0].name;

    return fixedName;
  }

  return "";
};

const setName = (array, property) => {
  const arrayFixed = array.map((item) => {
    item.nameConcat =
      item.description && item.description !== ""
        ? item.name + " - " + item.description
        : item.name;

    if (item[property] && item[property].length > 0) {
      item[property] = setName(item[property], "");
    }

    return item;
  });

  return arrayFixed;
};

const whichMenu = (fieldId, item) => {
  switch (fieldId) {
    case "diagnosis_id": {
      return item.menuDx;
    }
    case "poa": {
      return item.menuPoa;
    }
    case "revenue_code_id": {
      return item.menuRev;
    }
    case "procedure_id": {
      return item.menuPro;
    }
    default:
      return "";
  }
};

const getElementDetails = (el, diagnostics, services, hDx, hSer) => {
  const isDx = el.id.includes("_DX");
  const field = el.id.substring(el.id.indexOf("_"));
  const fieldId = isDx
    ? field.substring(1).toLowerCase().replace("_dx", "")
    : field.substring(1).toLowerCase();
  const array = isDx ? diagnostics : services;
  const header = isDx ? hDx : hSer;
  const rowNo = el.id.substring(0, el.id.indexOf("_"));
  const rowRef = el.name
    ? el.name.substring(el.name.indexOf("_")).substring(1)
    : null;

  return { isDx, field, fieldId, array, header, rowNo, rowRef };
};

const createID = (type, header, index, rowNo, format, change) => {
  let id = "";

  if (type === "lef") {
    id =
      header[index - 1].value === "diagnosis_description"
        ? rowNo + "_" + header[index - 2].value.toUpperCase()
        : header[index - 1].value === "poa" && format === "UB04" && !change
        ? rowNo + "_" + header[index - 3].value.toUpperCase()
        : header[index - 1].value === "price" ||
          header[index - 1].value === "total_charge"
        ? rowNo + "_" + header[index - 2].value.toUpperCase()
        : header[index - 1]
        ? rowNo + "_" + header[index - 1].value.toUpperCase()
        : null;
  }

  if (type === "rig") {
    id =
      header[index + 1].value === "diagnosis_description"
        ? rowNo + "_" + header[index + 2].value.toUpperCase()
        : header[index + 1].value === "admission" &&
          format === "UB04" &&
          !change
        ? rowNo + "_" + header[index + 3].value.toUpperCase()
        : header[index + 1].value === "price" ||
          header[index + 1].value === "total_charge"
        ? rowNo + "_" + header[index + 2].value.toUpperCase()
        : header[index + 1]
        ? rowNo + "_" + header[index + 1].value.toUpperCase()
        : null;
  }

  return id;
};

const messageFound = (type, item) => {
  switch (type) {
    case "dx": {
      return item.foundDiag === 1
        ? VueI18n.t("claim.enter-diagnosis-code")
        : item.foundDiag === 2
        ? VueI18n.t("claim.diagnosis-code-not-found")
        : VueI18n.t("claim.diagnosis-code-entered");
    }
    case "rev": {
      return item.foundRevenue === 2
        ? VueI18n.t("general.revenue-code-not-found")
        : VueI18n.t("general.enter-revenue-code");
    }
    case "pro": {
      return item.foundProcedure === 2
        ? VueI18n.t("general.procedure-code-not-found")
        : VueI18n.t("general.enter-procedure-code");
    }
  }
};

const showDataProperty = (type, id, array) => {
  if (array && array.length > 0) {
    const itemSelected = array.find((item) => {
      return item.id === id;
    });

    if (itemSelected)
      return type === "n" ? itemSelected.name : itemSelected.description;
  }

  return "";
};

const verifySearch = (property, item) => {
  const getCode = (item, codeKey) => {
    const code = item[codeKey].split(" ")[0];
    return code === "" ? null : code;
  };

  const verifyProperties = {
    diagnosis_id: (item) => {
      const code = getCode(item, "diagnosis_code");
      return code ? !item.diagnosis.find((dx) => dx.name === code) : false;
    },
    revenue_code_id: (item) => {
      const code = getCode(item, "rev_code");
      return code ? !item.revenueCodes.find((dx) => dx.name === code) : false;
    },
    procedure_id: (item) => {
      const code = getCode(item, "procedure_code");
      return code ? !item.procedures.find((dx) => dx.name === code) : false;
    },
  };

  const hasCode = verifyProperties[property];
  return hasCode ? hasCode(item) : false;
};

const setFirstRev = (type, array) => {
  switch (type) {
    case "code": {
      return array.length === 1 ? array[0].nameConcat : "";
    }
    case "id": {
      return array.length === 1 ? array[0].id : "";
    }
    case "selected": {
      return array.length === 1 ? array[0] : null;
    }
  }
};

const dxAlreadyEntered = (item, dxs) => {
  if (dxs.length <= 1) return false;

  const indexDx = dxs.findIndex((a) => a.pivote === item.pivote);
  if (indexDx === -1) return false;

  return dxs.some(
    (dx, index) =>
      index !== indexDx &&
      dx.diagnosis_code === item.diagnosis_code.toUpperCase()
  );
};

const correctServices = (
  services,
  programId,
  programs,
  patientChange,
  serverDate,
  format
) => {
  if (services.length === 0) return [];

  services.forEach((item) => {
    if (item.updateUnits) return;

    if (item.from_service && item.to_service) {
      if (item.from_service !== item.to_service) {
        calculateDiff(
          item,
          item.from_service,
          item.to_service,
          patientChange,
          format
        );
      } else {
        item.units_of_service = 1;
      }
    }

    if (item.from_service && item.to_service === "" && patientChange) {
      let to = "";

      if (!serverDate) {
        const findProgram = programs.find((fitem) => fitem.id === programId);
        to = findProgram?.date || "";
      }

      if (serverDate) to = serverDate;

      calculateDiff(item, item.from_service, to, patientChange, format);
    }

    calculateTotalChanges(item);
  });

  return services;
};

const calculateDiff = (item, f, t, patientChange, format) => {
  const from = new Date(f);
  const to = new Date(t);
  let diff = (to.getTime() - from.getTime()) / 1000 / 60 / 60 / 24;

  if (diff < 0) {
    diff = 1;
  } else {
    if (!patientChange) diff++;
  }

  item.units_of_service = diff;
  if (format === "UB04") calculateTotalChanges(item);
};

const calculateTotalChanges = (item) => {
  const price = item.price;
  const units =
    item.units_of_service && item.units_of_service !== 0
      ? new Decimal(item.units_of_service)
      : 0;

  const result =
    price * units === 0
      ? "0.00"
      : globalNumberFormat.intToStringFormatUsa((units * price).toString());

  item.total_charge = result;
};

const getTodayDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const fixPolicy = (policyObj, policiesSelected, property) => {
  const foundPolicy = policiesSelected.find(
    (selected) => policyObj.id === selected.id
  );
  return foundPolicy ? foundPolicy[property] : "";
};

const createName = (p) => {
  const type = p.type_responsibility.code ?? p.type_responsibility;
  const number = p.policy_number;
  const plan = p.insurance_plan?.name ?? "";

  return `${type} - ${number} - ${plan}`;
};

const abbProperty = (obj) => {
  if (!obj) return "-";
  return obj.abbreviation ? `${obj.abbreviation} - ${obj.name}` : obj.name;
};

const modifyArrayNotes = (notes, data) => {
  notes.map((note, index) => {
    if (
      note.admission_tracking &&
      note.admission_tracking.id === data.idTracking
    ) {
      const p = data.payload;

      note.note = p.note;
      note.policy_id = p.insurance_policy_id;
      note.title = `Note ${index + 1}`;
      note.admission_tracking.follow_up = p.follow_up;
      note.admission_tracking.follow_up = p.follow_up;
      if (note.admission_tracking.insurance_policy)
        delete note.admission_tracking.insurance_policy;
      note.admission_tracking.insurance_policy_id = p.insurance_policy_id;
      note.admission_tracking.is_contact_patient = p.is_contact_patient;
      note.admission_tracking.note = p.note;
      note.admission_tracking.ref_number = p.ref_number;
      note.admission_tracking.rep_name = p.rep_name;
      note.admission_tracking.source = p.source;
      note.admission_tracking.source_description = p.source_description;
      note.admission_tracking.tracking_date = p.tracking_date;
    }
  });

  return notes;
};

const validChangeTime = (value) => {
  if (value.includes(":mm:ss")) return value.replace(/:mm:ss$/, ":00:00");
  if (value.includes(":ss")) return value.replace(/:ss$/, ":00");

  return value;
};

const poas = [
  { id: "Y", name: "YES" },
  { id: "N", name: "NO" },
  { id: "U", name: "Unknown" },
  { id: "W", name: "Clinically undetermined" },
  { id: "1", name: "Unreported/Not used" },
];

const propertyIds = ["diagnosis_id", "revenue_code_id", "procedure_id"];

const fieldIdToValue = {
  diagnosis_id: "diagnosis",
  revenue_code_id: "revenueCodes",
  procedure_id: "procedures",
};

const propertyTypes = {
  diagnosis_id: "dx",
  revenue_code_id: "rev",
  procedure_id: "pro",
};

export {
  validDatesTableOtherInfo,
  validAdmissionDischarge,
  validDates,
  validDatesTableServices,
  validDatesPolicies,
  validParametersDates,
  validStatementCoversDates,
  isTableDiagComplete,
  isTableServComplete,
  validLimitUnits,
  validSequential,
  validUnitServices,
  validCorrectServices,
  setCode,
  setName,
  whichMenu,
  getElementDetails,
  createID,
  messageFound,
  showDataProperty,
  verifySearch,
  setFirstRev,
  dxAlreadyEntered,
  correctServices,
  getTodayDate,
  fixPolicy,
  createName,
  abbProperty,
  modifyArrayNotes,
  validChangeTime,
  poas,
  propertyIds,
  fieldIdToValue,
  propertyTypes,
};
