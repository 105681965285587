import axios from "@/plugins/axios";
import { policyForFormsAdapter } from "@/utils/policyForForms.adapter";

import dataForm from "./utils/data.form";
import { dataTable } from "./utils/data.table";
import insurancePolicy from "./utils/insurance.policy";
import { patientClaims } from "./utils/patient.claims";
import patientSendData from "./utils/patient.send.data";
import dataPatientSuperUser from "./utils_su/data.patient.superuser";

const initialTabIndex = "table";

/**
 * Sufix su is only Super user
 */

const state = () => ({
  billingCompanyId: "",
  tabIndexForm: 0,
  tavIndexFormValidate: 0,

  patient: undefined,
  patient_su: dataPatientSuperUser.initialForms, // Super User
  patients: [],
  tabActions: "create",
  tabIndex: initialTabIndex,
  loadingTable: false,
  loadingPatient: false,
  showAction: false,
  patientIdSelected: undefined,

  billingCompanies: [],
  billingCompaniesUtil: [],
  billingCompaniesSu: [],
  companies: [],

  diagnosis: [],
  typeDiagnosis: [],

  insuranceCompanies: [],
  insuranceCompaniesList: {
    numberOfPages: 0,
    count: 0,
    data: [],
  },

  forms: dataForm.setInitialForms(),

  insuranceCompaniesForms: [],
  allInsurancePlansForms: [],

  showModalCreatePatient: false,

  snackbar: {
    show: false,
    color: "",
    icon: "",
    message: "",
    errors: [],
  },
  currentPatient: {},
  patientsResult: [],
  marital_status: [],
  responsability_types: [],
  policy_types: [],
  relationship: [],
  address_types: [],

  // Companies
  patient_companies: {},
  loadingClaim: false,
  patient_claims: {
    numberOfPages: 0,
    count: 0,
    data: [],
  },
  edit_patient_companies: false,
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },

  loadingPolicy: false,
  insuranceForm: insurancePolicy.dataForm,
  insuranceFormCurrent: insurancePolicy.dataForm,
  tabPolicy: "add-policy",
  suffix: [],
  genders: [],
  countries: [],
  subscribers: [],
  langs: [],
  insurancePlans: [],

  options: {},
  complementaryPlan: [],
  pivoteRequest: null,
});

const mutations = {
  setSnackbar(state, snackbar) {
    state.snackbar = snackbar;
  },
  setPatients(state, patients) {
    state.patients = patients;
  },
  setPatientsResult(state, patients) {
    state.patientsResult = patients;
  },
  setPatient(state, patient) {
    state.patient = patient;
  },
  setTabIndex(state, tabValue) {
    state.tabIndex = tabValue;
  },
  setTabActions(state, tabActions) {
    state.tabActions = tabActions;
  },
  setPatientIdSelected(state, patientId) {
    state.patientIdSelected = patientId;
  },
  setShowAction(state, showAction) {
    state.showAction = showAction;
  },
  setLoading(state, loadingTable) {
    state.loadingTable = loadingTable;
  },
  setLoadingPatient(state, loadingPatient) {
    state.loadingPatient = loadingPatient;
  },
  setLoadingClaim(state, loadingClaim) {
    state.loadingClaim = loadingClaim;
  },
  setForms(state, dataForms) {
    state.forms = dataForms;
  },
  addPatientCompany(state, patient_companies) {
    state.patient_companies = patient_companies;
  },
  setEditPatientCompanies(state, edit) {
    state.edit_patient_companies = edit;
  },
  setBillingCompanies(state, billingCompanies) {
    state.billingCompanies = billingCompanies;
  },
  setBillingCompaniesUtilities(state, billingCompanies) {
    state.billingCompaniesUtil = billingCompanies;
  },
  setBillingCompaniesSu(state, billingCompaniesSu) {
    state.billingCompaniesSu = billingCompaniesSu;
  },
  setCompanies(state, companies) {
    const companiesConcatAbbreviation = [];

    companies.map((company) => {
      companiesConcatAbbreviation.push({
        id: company.id,
        name: `${company.abbreviation} - ${company.name}`,
        abbreviation: company.abbreviation,
      });
    });
    state.companies = companiesConcatAbbreviation;
  },
  setInsuranceCompanies(state, insuranceCompanies) {
    state.insuranceCompanies = insuranceCompanies;
  },
  setInsuranceCompaniesList(state, insuranceCompaniesList) {
    state.insuranceCompaniesList = insuranceCompaniesList;
  },
  setInsurancePlans(state, data) {
    if (state.forms.insurancePolicy[data.index]) {
      state.forms.insurancePolicy[data.index].insurancePlans =
        data.insurancePlans;
    }
  },
  setTabIndexForm(state, tabIndexForm) {
    state.tabIndexForm = tabIndexForm;
  },
  setInsuranceCompaniesForms(state, insuranceCompaniesForms) {
    state.insuranceCompaniesForms = insuranceCompaniesForms;
  },
  setAllInsurancePlansForms(state, allInsurancePlansForms) {
    state.allInsurancePlansForms = allInsurancePlansForms;
  },
  setShowModalCreatePatient(state, showModalCreatePatient) {
    state.showModalCreatePatient = showModalCreatePatient;
  },
  setListTypesDiagnosis(state, typeDiagnosis) {
    state.typeDiagnosis = typeDiagnosis;
  },
  setDiagnosis(state, diagnosis) {
    state.diagnosis = diagnosis;
  },
  setMaritalStatus(state, marital_status) {
    state.marital_status = marital_status;
  },
  setResponsabilityTypes(state, responsability_types) {
    state.responsability_types = responsability_types;
  },
  setAddressTypes(state, address_types) {
    state.address_types = address_types;
  },
  setPolicyTypes(state, policy_types) {
    state.policy_types = policy_types;
  },
  getRelationships(state, relationship) {
    state.relationship = relationship;
  },
  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },
  setBillinCompanyId(state, billingCompanyId) {
    state.billingCompanyId = billingCompanyId;
  },
  setPatientSu(state) {
    state.patient_su = dataPatientSuperUser.formData;
  },
  setCurrentPatient(state, currentPatient) {
    state.currentPatient = currentPatient;
  },
  setInsuranceForm(state, insuranceForm) {
    state.insuranceForm = insuranceForm;
  },
  setInsuranceFormCurrent(state, insuranceFormCurrent) {
    state.insuranceFormCurrent = insuranceFormCurrent;
  },
  setTabPolicy(state, tabPolicy) {
    state.tabPolicy = tabPolicy;
  },
  setPatientClaims(state, patient_claims) {
    state.patient_claims = patient_claims;
  },
  setLoadingPolicy(state, loadingPolicy) {
    state.loadingPolicy = loadingPolicy;
  },
  setSuffix(state, suffix) {
    state.suffix = suffix;
  },
  setGender(state, genders) {
    state.genders = genders;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setSubscribers(state, subscribers) {
    state.subscribers = subscribers;
  },
  setLangs(state, langs) {
    state.langs = langs;
  },
  setInsurancePlan(state, insurancePlans) {
    state.insurancePlans = insurancePlans;
  },
  refreshAddressTypes(state, forms) {
    state.forms = forms;
  },
  setOptions(state, options) {
    state.options = options;
  },
  setComplementaryPlan(state, complementaryPlan) {
    state.complementaryPlan = complementaryPlan;
  },
  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },
};

const actions = {
  setSnackbar({ commit }, snackbar) {
    commit("setSnackbar", snackbar);
  },
  setEditPatientCompanies({ commit }, edit) {
    commit("setEditPatientCompanies", edit);
  },
  setTabPolicy({ commit, state }, tabPolicy) {
    let insuranceFormCurrent = state.insuranceFormCurrent;

    if (tabPolicy === "view-policy") {
      insuranceFormCurrent.readonly = true;
    } else {
      insuranceFormCurrent.readonly = false;
    }

    if (state.tabActions === "create") {
      insuranceFormCurrent.billing_company_id =
        state.forms.patient.billingCompanyOwner.id;
    }

    commit("setInsuranceForm", insuranceFormCurrent);
    commit("setTabPolicy", tabPolicy);
  },
  setInsuranceForm({ commit }, insuranceForm) {
    commit("setInsuranceForm", insuranceForm);
  },
  async setPolicy({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/patient/${state.currentPatient.id}/get-policy/${data.policy.id}`,
          data
        )
        .then(async (result) => {
          let insuranceForm = await insurancePolicy.setInsurancePolicy(
            result.data,
            data.action
          );

          await dispatch(
            "getInsurancePlansByInsuranceCompanyID",
            data.policy.insurance_company_id
          ).then((plans) => {
            insuranceForm = {
              ...insuranceForm,
              ["insurancePlans"]: plans,
            };
          });

          commit("setInsuranceFormCurrent", insuranceForm);
          commit("setInsuranceForm", insuranceForm);

          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  resetInsuranceForm({ commit }) {
    commit("setInsuranceForm", insurancePolicy.dataForm);
    commit("setInsuranceFormCurrent", insurancePolicy.dataForm);
  },
  setPermissionType({ commit }, roles) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };

    if (roles) {
      roles.find((role) => {
        if (role.name === "Super User") {
          permissionType.isSuperUser = true;
        }

        if (role.name === "Billing Manager") {
          permissionType.isBillingManager = true;
        }
      });
    }

    commit("setPermissionType", permissionType);
  },
  setBillinCompanyId({ commit, dispatch }, billinCompanyId) {
    commit("setBillinCompanyId", billinCompanyId);

    if (billinCompanyId) {
      dispatch("getCompanyByBillingCompanyID", billinCompanyId);
    }
  },
  getPatients({ commit, state }) {
    let query = state.options.query ? state.options.query : "";
    let sortBy = state.options.options.sortBy ?? "";

    const options = {
      params: {
        query: query.replaceAll("-", ""),
        itemsPerPage: state.options.options.itemsPerPage,
        page: state.options.options.page,
        sortBy: sortBy,
        sortDesc: sortBy ? (state.options.options.sortDesc ? 1 : 0) : "",
        ...state.options.searchOn,
      },
    };
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .get("/patient", options)
        .then(async (result) => {
          const patients = await dataTable(result.data.data);
          if (!state.options.pivote) {
            commit("setPatients", patients);
          }
          if (
            state.options.pivote &&
            state.options.pivote === state.pivoteRequest
          ) {
            commit("setPatients", patients);
            commit("setPivoteRequest", null);
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  },
  setOption({ commit }, options) {
    commit("setOptions", options);
  },
  getListPatients(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`patient/get-list?billing_company_id=${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getComplementaryPlan({ commit, state }, data) {
    const url = data.params.billing_company_id
      ? `patient/${data.params.patient_id}/get-list-policies?billing_company_id=${data.params.billing_company_id}`
      : `patient/${state.currentPatient.id}/get-list-policies`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data);

          commit("setComplementaryPlan", result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  setForm({ commit }, data) {
    dataForm.setFormData(data);
    commit("setForms", dataForm.formData);
  },

  getPatientById({ commit, dispatch }, patientId) {
    commit("setLoadingPatient", true);
    dispatch("setEditPatientCompanies", false);

    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/${patientId}`)
        .then(async (result) => {
          await dispatch("setPatientResponse", result);
          resolve(result.data);
          commit("setComplementaryPlan", result);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoadingPatient", false);
        });
    });
  },

  async setPatientResponse({ state, commit, dispatch }, result) {
    let paramsSubscribers = {
      params: {
        patient_id: null,
        billing_company_id: null,
      },
    };

    commit("setCurrentPatient", result.data);
    let patientData = await dataForm.setPatient(result.data);

    commit("setPatient", patientData);

    await dispatch(
      "getCompanyByBillingCompanyID",
      patientData.patient.billingCompanyOwner.id
    );

    paramsSubscribers = {
      params: {
        patient_id: result.data.id,
        billing_company_id: null,
      },
    };

    await dispatch("getSubscribers", paramsSubscribers);

    if (state.tabActions === "view-patient") {
      commit("setForms", {
        ...state.patient,
        readonly: true,
      });
    }

    if (state.tabActions === "update-patient") {
      commit("setForms", {
        ...state.patient,
        readonly: false,
      });
    }

    if (state.permissionType.isSuperUser) {
      commit("setPatientSu");
    }
  },

  async setPatientByBillingCompanyID({ state, commit }, billingCompanyId) {
    if (
      state.permissionType.isSuperUser &&
      state.tabActions === "update-patient"
    ) {
      let patientData = await dataPatientSuperUser.setFormDataEdit(
        state.currentPatient,
        billingCompanyId
      );

      commit("setPatient", patientData);

      commit("setForms", {
        ...state.patient,
        readonly: false,
      });
    }
  },

  changeStatusPatient({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/patient/change-status/${data.patientId}`, {
          status: data.status,
        })
        .then((result) => {
          dispatch("getPatients");
          commit("setPatient", {
            ...state.patient,
            status: data.status,
          });
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getPatientBySSN({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/search`, data)
        .then((result) => {
          commit("setPatientsResult", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  setTabIndex({ commit }, tabValue) {
    commit("setTabIndex", tabValue);
  },
  resetTabIndex({ commit }) {
    commit("setTabIndex", initialTabIndex);
  },
  resetPatient({ commit }, patient) {
    commit("setPatient", patient);
    commit("setPatientIdSelected", undefined);
  },
  setPatientIdSelected({ commit }, patientId) {
    commit("setPatientIdSelected", patientId);
  },
  setTabActions({ state, commit }, tabActions) {
    commit("setTabActions", tabActions);

    if (state.patient) {
      if (state.tabActions === "view-patient") {
        commit("setForms", {
          ...state.patient,
          readonly: true,
        });
      }

      if (state.tabActions === "update-patient") {
        commit("setForms", {
          ...state.patient,
          readonly: false,
        });
      }
    }

    if (tabActions === "tablePolicy-patient") {
      commit("setForms", dataForm.setInitialForms());
    }
  },
  setShowAction({ commit }, showAction) {
    commit("setShowAction", showAction);
  },
  setForms({ commit }, dataForms) {
    commit("setForms", dataForms);
  },
  addPatientCompany({ commit }, company) {
    commit("addPatientCompany", company);
  },
  getBillingCompanies({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/patient/get-list-billing-companies", params)
        .then((result) => {
          commit("setBillingCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBillingCompaniesUtilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/patient/get-list-billing-companies", { params: { edit: false } })
        .then((result) => {
          commit("setBillingCompaniesUtilities", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getCompanyByBillingCompanyID({ commit }, billingCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${billingCompanyId}`)
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getCompanyByBillingCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/`)
        .then((result) => {
          commit("setCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCompanyByIDBillingCompany(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/get-list-by-billing-company/${data.id}`, {
          params: data.params,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getInsuranceCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/insurance-company")
        .then((result) => {
          commit("setInsuranceCompanies", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  getInsurancePlansByInsuranceCompaniesID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/insurance-plan/insurance-company/${data.id}/get-by-insurance-company`
        )
        .then((result) => {
          commit("setInsurancePlans", {
            insurancePlans: result.data,
            index: data.index,
          });
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getInsurancePlansByInsuranceCompanyID(_, insuranceCompanyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/insurance-plan/insurance-company/${insuranceCompanyId}/get-by-insurance-company`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getInsurancePlans({ commit }, billingCompanyId = 0) {
    return new Promise((resolve, reject) => {
      const params = {
        params: {
          groupBy: true,
          billing_company_id: billingCompanyId,
        },
      };

      axios
        .get(`/insurance-plan/get-list`, params)
        .then((result) => {
          commit("setInsurancePlan", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getInsuranceGroupBy({ commit }) {
    return new Promise((resolve, reject) => {
      const url = `/insurance-plan/get-list?groupBy=true`;
      axios
        .get(url)
        .then((result) => {
          commit("setInsurancePlan", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  setTabIndexForm({ commit }, tabIndexForm) {
    commit("setTabIndexForm", tabIndexForm);
  },
  resetForms({ commit }) {
    commit("setForms", dataForm.setInitialForms());
  },

  getMaritalStatus({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-marital-status`)
        .then((result) => {
          commit("setMaritalStatus", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getResponsabilityTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-responsibility-type`)
        .then((result) => {
          commit("setResponsabilityTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAddressTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-address-type`)
        .then((result) => {
          commit("setAddressTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPolicyTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-insurance-policy-type`)
        .then((result) => {
          commit("setPolicyTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getRelationships({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-relationship`)
        .then((result) => {
          commit("getRelationships", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async savePatient({ state, commit, dispatch }) {
    await patientSendData.setBodyResquest(state.forms);
    const data = patientSendData.form;

    return new Promise((resolve, reject) => {
      axios
        .post("/patient/", data)
        .then((result) => {
          commit("setForms", dataForm.setInitialForms());
          dispatch("getPatients");
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  async saveDraftPatient({ state, commit, dispatch }) {
    await patientSendData.setBodyResquest(state.forms);
    const data = patientSendData.form;

    return new Promise((resolve, reject) => {
      axios
        .post("/patient/", data)
        .then((result) => {
          commit("setForms", dataForm.setInitialForms());
          dispatch("getPatients");
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  async createPatient({ state, dispatch, commit }) {
    await patientSendData.setBodyResquest(state.forms);
    const data = patientSendData.form;

    return new Promise((resolve, reject) => {
      axios
        .post("/patient/", data)
        .then(async (result) => {
          await axios.get(`/patient/${result.data.id}`).then(async (result) => {
            commit("setCurrentPatient", result.data);
          });

          dispatch("getPatients");
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  async updatePatient({ state, dispatch }) {
    await patientSendData.setBodyResquest(state.forms);

    return new Promise((resolve, reject) => {
      axios
        .put(`/patient/${state.patient.id}`, patientSendData.form)
        .then(async (result) => {
          await dispatch("getAddressTypes");
          await dispatch("setPatientResponse", result);
          await dispatch("addressTypesRefresh");
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  async savePolicy({ state, dispatch }) {
    const data = insurancePolicy.formatBodyRequest(
      state.insuranceForm,
      state.permissionType
    );

    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/patient/add-policy-to-patient/${state.currentPatient.id}`,
          data
        )
        .then((result) => {
          dispatch("resetInsuranceForm");
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  async updatePolicy({ state, commit }) {
    const data = insurancePolicy.formatBodyRequest(
      state.insuranceForm,
      state.permissionType
    );

    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/patient/${state.currentPatient.id}/edit-policy/${state.insuranceForm.id}`,
          data
        )
        .then((result) => {
          commit("setInsuranceFormCurrent", state.insuranceForm);
          commit("setInsuranceForm", state.insuranceForm);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  deletePolicy({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/patient/${state.currentPatient.id}/remove-policy/${id}`)
        .then((result) => {
          const policies = state.patient.insurancePolicies.filter(
            (policy) => policy.id != id
          );
          commit("setPatient", {
            ...state.patient,
            insurancePolicies: policies,
          });

          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },
  viewPolicy({ state, commit }, id) {
    const policyIndex = state.patient.insurancePolicies.findIndex(
      (policy) => policy.id === id
    );
    const policyForFormAdapter = policyForFormsAdapter(
      state.patient.insurancePolicies[policyIndex],
      state.insuranceCompaniesForms,
      state.allInsurancePlansForms,
      policyIndex
    );

    commit("setForms", {
      ...state.forms,
      insurancePolicy: [policyForFormAdapter],
      readonly: true,
    });
  },
  modifyPolicy({ state, commit }, id) {
    const policyIndex = state.patient.insurancePolicies.findIndex(
      (policy) => policy.id === id
    );

    const policyForFormAdapter = policyForFormsAdapter(
      state.patient.insurancePolicies[policyIndex],
      state.insuranceCompaniesForms,
      state.allInsurancePlansForms,
      policyIndex
    );

    commit("setForms", {
      ...state.forms,
      insurancePolicy: [policyForFormAdapter],
      readonly: false,
    });
  },

  setShowModalCreatePatient({ commit }, showModalCreatePatient) {
    commit("setShowModalCreatePatient", showModalCreatePatient);
  },
  getListTypesDiagnosis({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/injury/get-list-type-diags")
        .then((result) => {
          commit("setListTypesDiagnosis", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  getDiagnosis({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/diagnosis/")
        .then((result) => {
          commit("setDiagnosis", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  async savePatientCompanies({ state, dispatch }) {
    const data = state.patient_companies;

    return new Promise((resolve, reject) => {
      axios
        .patch(`/patient/add-companies-to-patient/${state.patient.id}`, data)
        .then((result) => {
          dispatch("getPatientById", state.patient.id);
          resolve(result.data);
        })
        .catch((error) => reject(error.response.data));
    });
  },

  getPolicies({ commit, state }, data) {
    commit("setLoadingPolicy", true);
    commit("setInsuranceCompaniesList", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/${state.currentPatient.id}/get-policies`, data)
        .then((result) => {
          let insuranceCompaniesList =
            insurancePolicy.setInsuranceCompaniesList(result.data);
          commit("setInsuranceCompaniesList", insuranceCompaniesList);
          commit("setLoadingPolicy", false);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  setLoadingPolicy({ commit }, data) {
    commit("setLoadingPolicy", data);
  },
  getClaims({ commit, state }, data) {
    data.params.patient_id = state.patient.id;
    commit("setLoadingClaim", true);
    commit("setPatientClaims", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-all-server`, data)
        .then(async (result) => {
          let claims = await patientClaims(result.data);
          commit("setPatientClaims", claims);
          commit("setLoadingClaim", false);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  changeStatusPolicy({ state }, policy) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/patient/${state.currentPatient.id}/change-status-policy/${policy.id}`,
          policy.data
        )
        .then(async (result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getSuffix({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/get-list-name-suffix")
        .then((result) => {
          commit("setSuffix", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getGender({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/get-list-gender")
        .then((result) => {
          commit("setGender", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/address/get-list-countries")
        .then((result) => {
          commit("setCountries", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getSubscribers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/patient/get-subscribers", data)
        .then((result) => {
          commit("setSubscribers", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getLangs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/setting/get-list-langs", data)
        .then((result) => {
          commit("setLangs", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  resetSubscribers({ commit }) {
    commit("setSubscribers", []);
  },

  resetInsuranceCompaniesList({ commit }) {
    let insuranceCompaniesList = {
      numberOfPages: 0,
      count: 0,
      data: [],
    };

    commit("setInsuranceCompaniesList", insuranceCompaniesList);
  },

  addressTypesRefresh({ state, commit }) {
    let addresses = [];
    let selectedType = [];

    state.forms.addresses.map((address) => {
      selectedType.push(address.address_type_id);
    });

    state.forms.addresses.map((address) => {
      let addressTypes = [];
      state.address_types.map((addressType) => {
        if (
          address.address_type_id === addressType.id ||
          !selectedType.includes(addressType.id)
        ) {
          addressTypes.push({
            id: addressType.id,
            name: addressType.name,
          });
        }
      });

      addresses.push({
        address_type_id: address.address_type_id ?? "",
        address: address.address ?? "",
        apt_suite: address.apt_suite ?? "",
        city: address.city ?? "",
        state: address.state ?? "",
        zip: address.zip ?? "",
        country: address.country ?? "",
        main_address: address.main_address ?? false,
        address_types: addressTypes,
      });
    });

    let formsRefresh = {
      ...state.forms,
      addresses: addresses,
    };

    commit("refreshAddressTypes", formsRefresh);
  },

  validatePatient({ state }) {
    return new Promise((resolve, reject) => {
      const data = {
        params: {
          billing_company_id: state.forms.patient.billingCompanyOwner.id,
          first_name: state.forms.patient.firstName,
          last_name: state.forms.patient.lastName,
          date_of_birth: state.forms.patient.dateOfBirth,
          ssn: state.forms.patient.ssn,
          company_id: state.forms.patient.company_id.id,
        },
      };

      axios
        .get("patient/exist", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error.response));
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
