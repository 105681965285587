import { DateTime } from "luxon";

import axios from "@/plugins/axios";

const state = () => ({
  loadingDenial: false,
  currentDenial: null,
  billing_company_id: null,
  isSuperUser: null,
  formDenial: null,
  formRefile: null,
  validFormTracking: false,
  validFormRefile: false,
  status: [],
  statusTracking: [],
  substatus: [],
  substatus_tracking: [],
  place_of_services: [],
  type_of_services: [],
  epsdts: [],
  family_plannings: [],
  insurancePolicies: [],
  insurancePoliciesFirstCase: [],
  insurancePoliciesOthersCase: [],
  departmentResponsibilities: [],
  responsability_types: [],
  insurancePolicySelected: 0,
  tabView: "tabViewDenial-1",
  genders: [],
  countries: [],
  refile_reasons: [],
  trackingForm: {
    claim_id: "",
    claim_number: "",
    interface_type: "",
    is_reprocess_claim: false,
    is_contact_to_patient: false,
    contact_through: "",
    rep_name: "",
    ref_number: "",
    claim_status: "",
    claim_sub_status: "",
    tracking_date: "",
    resolution_time: "",
    past_due_date: "",
    follow_up: "",
    department_responsible: "",
    policy_responsible: "",
    tracking_note: "",
    response_details: "",
  },
  refileForm: {
    refile_type: 0,
    policy_number: "",
    is_cross_over: false,
    cross_over_date: "",
    note: "",
    original_claim_id: "",
    refile_reason: "",
    claim_id: "",
  },
  permissionType: {
    isSuperUser: false,
    isBillingManager: false,
  },
  permissions: {
    view: false,
    edit: false,
    tracking: false,
    refile: false,
    preview: false,
    status: false,
    eob: false,
  },
  getDenials: null,
  relationship: [],
  pivoteRequest: null,
});

const mutations = {
  setStatus(state, status) {
    state.status = status;
  },

  setStatusTracking(state, status) {
    state.statusTracking = status;
  },

  setSubStatus(state, substatus) {
    state.substatus = substatus;
  },

  setSubStatusTracking(state, substatus_tracking) {
    state.substatus_tracking = substatus_tracking;
  },

  setPermissionType(state, permissionType) {
    state.permissionType = permissionType;
  },

  setPermission(state, permissions) {
    state.permissions = permissions;
  },

  setLoadingDenial(state, loadingDenial) {
    state.loadingDenial = loadingDenial;
  },

  setCurrentDenial(state, currentDenial) {
    state.currentDenial = currentDenial;
  },

  setInsurancePolicySelected(state, insurancePolicySelected) {
    state.insurancePolicySelected = insurancePolicySelected;
  },

  setTabView(state, tabView) {
    state.tabView = tabView;
  },

  setPlaceOfServices(state, place_of_services) {
    state.place_of_services = place_of_services;
  },

  setInsurancePolicies(state, insurancePolicies) {
    state.insurancePolicies = insurancePolicies;
  },

  setInsurancePoliciesRefile(state, data) {
    if (data.refile) {
      state.insurancePoliciesFirstCase = data.insurancePolicies;
    } else {
      state.insurancePoliciesOthersCase = data.insurancePolicies;
    }
  },

  setDepartmentResponsibilities(state, departmentResponsibilities) {
    state.departmentResponsibilities = departmentResponsibilities;
  },

  setClaimServices(state, claimServices) {
    state.place_of_services = [];
    state.type_of_services = [];
    state.epsdts = [];
    state.family_plannings = [];

    state.place_of_services = claimServices.place_of_services;
    state.type_of_services = claimServices.type_of_services;
    state.epsdts = claimServices.epsdts;
    state.family_plannings = claimServices.family_plannings;
  },

  setTrackingForm(state, tracking) {
    Object.assign(state.trackingForm, tracking);
  },

  setFormRef(state, formDenial) {
    state.formDenial = formDenial;
  },

  setFormRefileRef(state, formRefile) {
    state.formRefile = formRefile;
  },

  setValidForm(state, valid) {
    state.validFormTracking = valid;
  },

  setValidRefileForm(state, valid) {
    state.validFormRefile = valid;
  },

  setGender(state, genders) {
    state.genders = genders;
  },

  setCountries(state, countries) {
    state.countries = countries;
  },

  setResponsabilityTypes(state, responsability_types) {
    state.responsability_types = responsability_types;
  },

  setRefileReasons(state, refile_reasons) {
    state.refile_reasons = refile_reasons;
  },

  setGetDenials(state, getDenials) {
    state.getDenials = getDenials;
  },

  setRelationships(state, relationship) {
    state.relationship = relationship;
  },

  setPivoteRequest(state, pivoteRequest) {
    state.pivoteRequest = pivoteRequest;
  },

  setRefileForm(state, refileForm) {
    let dateStatus = null;

    if (
      refileForm.refile_type === 0 &&
      refileForm.cross_over_date !== "" &&
      refileForm.is_cross_over
    ) {
      const date = DateTime.fromSQL(refileForm.cross_over_date);

      dateStatus = date.toFormat("MM/dd/yyyy");
    }

    Object.assign(state.refileForm, {
      refile_type: refileForm.refile_type,
      policy_number:
        refileForm.refile_type === 0 ? refileForm.policy_number : "",
      is_cross_over:
        refileForm.refile_type === 0 ? refileForm.is_cross_over : "",
      cross_over_date: dateStatus,
      note: refileForm.note,
      original_claim_id:
        refileForm.refile_type === 1 ? refileForm.original_claim_id : "",
      refile_reason:
        refileForm.refile_type === 1 || refileForm.refile_type === 2
          ? refileForm.refile_reason
          : "",
      claim_id: refileForm.claim_id,
    });
  },
};

const actions = {
  getClaims(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/denial/get-all-server", { params: data })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimById({ commit }, claimId) {
    commit("setLoadingDenial", true);

    return new Promise((resolve, reject) => {
      axios
        .get(`/denial/${claimId}`)
        .then((result) => {
          commit("setInsurancePolicySelected", 0);
          commit("setCurrentDenial", result.data);
          commit("setRefileReasons", result.data.refile_reasons);
          commit("setLoadingDenial", false);
          resolve(result.data);
        })
        .catch((error) => {
          commit("setLoadingDenial", false);
          reject(error.response.data);
        });
    });
  },

  getStatus({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-status?type=denial")
        .then((result) => {
          commit("setStatus", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getStatusForTracking({ commit }, statusId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/get-list-status?current_id=${statusId}`)
        .then((result) => {
          commit("setStatusTracking", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getSubStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim-sub-status/get-list", { params: data })
        .then((result) => {
          commit("setSubStatus", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getSubStatusTracking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/claim-sub-status/get-list-by-billing-company/${data.status_id}/${data.billing_company_id}`
        )
        .then((result) => {
          commit("setSubStatusTracking", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  setPermissionType({ commit }, role) {
    let permissionType = {
      isSuperUser: false,
      isBillingManager: false,
    };
    if (role === 1) {
      permissionType.isSuperUser = true;
    }

    if (role === 2) {
      permissionType.isBillingManager = true;
    }

    commit("setPermissionType", permissionType);

    commit("setPermissionType", permissionType);
  },

  setPermission({ commit }, menu) {
    let permissions = menu["denial management"];
    commit("setPermission", {
      view: permissions.view,
      edit: permissions.edit,
      tracking: permissions["tracking claim"],
      refile: permissions.refile,
      preview: permissions.show,
      status: permissions.status,
      eob: permissions.eob,
    });
  },

  getPlaceOfServices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-place-of-services?facility_id=${data}`)
        .then((result) => {
          commit("setPlaceOfServices", result.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getClaimServices({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/claim/get-list-claim-services")
        .then((result) => {
          commit("setClaimServices", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePolicies({ commit }, claim) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-insurance-policies/${claim}`)
        .then((result) => {
          commit("setInsurancePolicies", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getInsurancePoliciesRefile({ commit }, data) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (data.refile) {
        options = {
          params: {
            type: "refile",
          },
        };
      }

      axios
        .get(`/claim/${data.claim}/policies`, options)
        .then((result) => {
          const dataPolicies = {
            refile: data.refile,
            insurancePolicies: result.data,
          };
          commit("setInsurancePoliciesRefile", dataPolicies);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getDepartmentResponsibilities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`claim/get-list-department-responsibilities`)
        .then((result) => {
          commit("setDepartmentResponsibilities", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  createTracking({ state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/denial", state.trackingForm)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  createRefile({ state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/denial/refile", state.refileForm)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getCheckStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim/get-check-status/${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getGender({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/get-list-gender")
        .then((result) => {
          commit("setGender", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/address/get-list-countries")
        .then((result) => {
          commit("setCountries", result.data);
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getResponsabilityTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-responsibility-type`)
        .then((result) => {
          commit("setResponsabilityTypes", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateStatus(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/change-status/${data.id}`, data.data_status)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getListStatusAvailables(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/claim-sub-status/get-list-status?current_id=${data}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  subStatusSaveDraft(_, data) {
    return new Promise((resolve, reject) => {
      let url = data.billing_company_id
        ? `/claim-sub-status/get-list-by-billing-company/${data.status_id}/${data.billing_company_id}`
        : `/claim-sub-status/get-list-by-billing-company/${data.status_id}`;

      axios
        .get(url)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  saveNote(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/claim/add-note-current-status/${data.id}`, data.form)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },

  getEobByURL(_, eob_file) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${eob_file}`, {
          responseType: "arraybuffer",
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getRelationships({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/patient/get-list-relationship`)
        .then((result) => {
          commit("setRelationships", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
