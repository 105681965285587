import Vue from "vue";

import axios from "../../plugins/axios";
import apps from "./apps";

function findIndex(array, app) {
  return array.findIndex((val) => val.id == app.id);
}

function getPinedWindows(array) {
  return array.filter((val) => val.alwaysOnTop == true);
}
export default {
  namespaced: true,

  state: () => ({
    // ...apps,
    InitLoading: true,
    apps: [],
    appsByCat: [],
    appCat: [],
    taskbar: [],
    desktop: [],
    windows: [],
    currentApp: null,
    focusAppName: null,
    menu: false,
    colide: false,
    colides: [],
    user: "",
    config: {
      style: "windows",
      background: "13",
      taskbar: "full",
      dark: "light",
      lang: "en",
    },
    spot: false,
    sessionTime: 0,
    sessionModal: false,
    isTooManyRequest: false,
  }),
  actions: {
    initConfig({ state }) {
      let config = JSON.parse(localStorage.getItem("config"));
      config
        ? (state.config = config)
        : localStorage.setItem("config", JSON.stringify(state.config));
    },

    async init({ state }) {
      document.addEventListener("mousemove", (e) => {
        if (state.colides.length >= 1) {
          if (e.clientY >= window.innerHeight - 10) {
            state.colide = false;
          } else {
            state.colide = true;
          }
        }
      });

      axios
        .get(`/auth/me`)
        .then(({ data, headers }) => {
          state.sessionTime = headers["inactivity-time"];
          // no in permissions
          data.menu["setting"] = { view: true };
          data.menu["technical support"] = { view: true };
          // data.menu["payments management"] = { view: true };
          data.menu["role management"] = { view: true };

          // data.menu["file"] = { view: true };
          data.menu["notes"] = { view: true };
          data.menu["calculator"] = { view: true };
          data.menu["messenger"] = { view: true };
          // data.menu["browser"] = { view: true };
          data.menu["guidelines"] = { view: true };
          state.user = data;
          state.config.style =
            data.profile.settings.nav_bar_position === "bottom"
              ? "windows"
              : "macos";
          state.config.background =
            data.profile.style.background === "default"
              ? "13"
              : data.profile.style.background;
          state.config.taskbar = data.profile.settings.dock;
          state.config.dark = data.profile.dark_mode ? "dark" : "light";

          const config = state.config;
          localStorage.setItem("config", JSON.stringify(config));

          state.apps = [];
          state.taskbar = [];
          state.desktop = [];
          for (const key in data.menu) {
            if (apps.apps[key] && data.menu[key].view) {
              apps.apps[key].taskbar
                ? state.taskbar.push(apps.apps[key])
                : null;
              apps.apps[key].desktop
                ? state.desktop.push(apps.apps[key])
                : null;
              state.apps.push(apps.apps[key]);
            }
          }
          if (data.taskbars.length > 0) {
            data.taskbars.map((task) => {
              apps.apps[task.module].taskbar = true;
              state.taskbar.push(apps.apps[task.module]);
            });
          }
          state.appsByCat["All"] = [];
          for (const key in data.menu_by_category) {
            state.appCat.push(key);
            state.appsByCat[key] = [];
            for (const key1 in data.menu_by_category[key]) {
              if (
                apps.apps[key1.toLowerCase()] &&
                data.menu_by_category[key][key1].view
              ) {
                state.appsByCat[key].push(apps.apps[key1.toLowerCase()]);
                state.appsByCat["All"].push(apps.apps[key1.toLowerCase()]);
              }
            }
          }

          state.InitLoading = false;
        })
        .catch(() => {
          this.$axios
            .get("/auth/logout")
            .then(() => {
              window.location = "/";
            })
            .catch(() => {
              window.location = "/";
            });
        });
    },

    setProps({ state }) {
      state.currentApp.isOpen = true;
      state.currentApp.isMinimize = false;
      state.currentApp.isMaximize = true;
    },

    insertIntoTaskbar({ state, dispatch }) {
      let addTask = state.taskbar.some(
        (ele) => ele.name === state.currentApp.name
      );
      if (!addTask) {
        state.taskbar.push(state.currentApp);
      }

      dispatch("focusApp");
    },

    insertIntoWindows({ state, dispatch }) {
      const index = getPinedWindows(state.windows).length;
      dispatch("setProps");
      let addWindows = state.windows.some(
        (ele) => ele.name === state.currentApp.name
      );
      if (index <= 0) {
        if (!addWindows) {
          state.windows.push(state.currentApp);
        }
      } else {
        if (!addWindows) {
          state.windows.splice(
            state.windows.length - index,
            0,
            state.currentApp
          );
        }
      }

      state.taskbar.includes(state.currentApp)
        ? dispatch("focusApp")
        : dispatch("insertIntoTaskbar");
    },

    // eslint-disable-next-line no-unused-vars
    focusApp({ state }, app) {
      if (app) {
        Vue.set(
          state.windows,
          state.windows.findIndex((val) => val.name == app.name),
          app
        );
      }
      // if (app) {
      //   const index = getPinedWindows(state.windows).length;
      //   const pined = getPinedWindows(state.windows);
      //   if (index <= 0) {
      //     state.windows.push(
      //       state.windows.splice(
      //         state.windows.findIndex((val) => val.name == app.name),
      //         1
      //       )[0]
      //     );
      //   } else {
      //     const result = pined.find((val) => val.name == app.name);
      //     if (!result) {
      //       state.windows.splice(
      //         state.windows.length - index - 1,
      //         0,
      //         state.windows.splice(
      //           state.windows.findIndex((val) => val.name == app.name),
      //           1
      //         )[0]
      //       );
      //     }
      //   }
      // } else {
      //   this._vm.$nextTick(() => {
      //     this._vm.$window.show(state.currentApp.name);
      //   });
      // }
      // state.focusAppName = state.currentApp.name;
    },

    openApp({ state, dispatch }, app) {
      if (app.isMinimize && app.isOpen) {
        if (state.currentApp && state.currentApp.isMaximize)
          dispatch("minimize", state.currentApp);
        state.focusAppName = app.name;
        app.isMinimize = false;
        Vue.set(
          state.windows,
          state.windows.findIndex((val) => val.name === app.name),
          app
        );
      } else {
        if (
          state.currentApp &&
          state.currentApp.name === app.name &&
          state.currentApp.isOpen
        )
          dispatch("minimize", state.currentApp);
        else {
          if (
            state.currentApp &&
            state.currentApp.name !== app.name &&
            state.currentApp.isMaximize
          )
            dispatch("minimize", state.currentApp);
          app.isMinimize = false;
          state.currentApp = app;
          state.focusAppName = app.name;

          state.windows.some((item) => item.name === app.name)
            ? dispatch("focusApp", app)
            : dispatch("insertIntoWindows");
        }
      }
    },

    close({ state }, name) {
      localStorage.removeItem("module");
      state.taskbar[
        state.taskbar.findIndex((app) => app.name === name)
      ].isOpen = false;
      state.taskbar[state.taskbar.findIndex((app) => app.name === name)].taskbar
        ? null
        : state.taskbar.splice(
            state.taskbar.findIndex((app) => app.name === name),
            1
          );
      // const index = state.windows.findIndex((object) => {
      //   return object.name === name;
      // });
      // console.log(index);
      // state.windows.splice(index, 1);
      // console.log(state.windows);
      // state.focusAppName = null;
      state.windows.splice(
        state.windows.findIndex((object) => object.name === name),
        1
      );
      //   state.windows.length
      //     ? (state.focusAppName = state.windows[state.windows.length - 1].name)
      //     : (state.focusAppName = null);
    },

    pin({ state }, app) {
      app.alwaysOnTop = !app.alwaysOnTop;
      Vue.set(state.windows, findIndex(state.windows, app), app);
    },

    maximize({ state }, app) {
      app.isMaximize = !app.isMaximize;
      Vue.set(state.windows, findIndex(state.windows, app), app);
    },

    minimize({ state }, app) {
      state.focusAppName = null;
      app.isMinimize = true;
      Vue.set(state.windows, findIndex(state.windows, app), app);
    },

    sendToDesktop({ state }, app) {
      app.desktop
        ? state.desktop.splice(findIndex(state.desktop, app), 1)
        : state.desktop.push(app);
      app.desktop = !app.desktop;
    },

    pinToTaskbar({ state }, app) {
      const module = app.key;
      return new Promise((resolve) => {
        axios
          .post(`user/taskbar`, {
            module: module,
          })
          .then((result) => {
            console.log(result);
            resolve(result.data);
            let addtaskbar = state.taskbar.some((ele) => ele.name === app.name);
            app.taskbar = true;
            if (!addtaskbar) {
              state.taskbar.push(app);
            } /* else if (!app.isOpen) {
                state.taskbar.splice(findIndex(state.taskbar, app), 1);
              }*/

            // app.taskbar = !app.taskbar;
          });
      });
    },
    UnpinToTaskbar({ state }, app) {
      const module = app.key;
      return new Promise((resolve) => {
        axios
          .put("user/taskbar-unpin", {
            module: module,
          })
          .then((result) => {
            app.taskbar = false;
            resolve(result.data);
            if (!app.isOpen) {
              state.taskbar.splice(findIndex(state.taskbar, app), 1);
            }
            // app.taskbar = !app.taskbar;
          });
      });
    },
  },
  mutations: {
    setSpot(state, n) {
      state.spot = n;
    },
  },
};
