import Vue from "vue";
import Vuex from "vuex";

import admission_encounter from "./admission_encounter";
import audit from "./audit";
import auth from "./auth";
import billing_company from "./billing_company";
import claim from "./claim";
import claim_batch from "./claim_batch";
import claim_rules from "./claim_rules";
import claim_substatus from "./claim_substatus";
import clearing_house from "./clearing_house";
import company from "./company";
import denial from "./denial/index";
import diagnosis from "./diagnosis";
import facility from "./facility";
import file_queue from "./file_queue";
import health_professional from "./health_professional";
import insurance_company from "./insurance_company";
import insurance_plan from "./insurance_plan";
import ledger from "./ledger";
import modifier from "./modifier";
import spotlight from "./modules/spotlight";
import npi from "./npi";
import patient from "./patient";
import payment_management from "./payment_management";
import procedure from "./procedure";
import profile from "./profile/index";
import reports from "./reports";
import role from "./role";
import system from "./system";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    system,
    facility,
    audit,
    npi,
    clearing_house,
    billing_company,
    company,
    diagnosis,
    patient,
    user,
    insurance_company,
    insurance_plan,
    auth,
    role,
    health_professional,
    modifier,
    procedure,
    claim,
    claim_rules,
    admission_encounter,
    claim_batch,
    claim_substatus,
    reports,
    spotlight,
    profile,
    denial,
    payment_management,
    ledger,
    file_queue,
  },
});
